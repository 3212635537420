import React from "react";
import { TabChildrenHeader } from "src/FSD/widgets/v2/TabsPanel";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { useParams } from "react-router-dom";
import { ApiPrimaryProjectType } from "src/types/api/primaryTypes/apiPrimaryProjectType";
import { SearchBar } from "src/FSD/shared/uiKit/v2/SearchBar/SearchBar";
import { useSourceData } from "src/FSD/widgets/v2/projectProcess/std/SourceData/lib/hooks/useSourceData";
import styles from "./SourceData.module.scss";
import { SourceDataContent } from "./SourceDataContent";

type TProps = {
  can: ApiPrimaryProjectType["can"];
};

export const SourceData = ({ can }: TProps) => {
  const params = useParams<{ id: string }>();
  const { data, activeTab, isLoading, searchValue, setSearch } = useSourceData({
    id: Number(params.id),
  });

  return (
    <div className={styles.wrapper}>
      <TabChildrenHeader
        title="Исходные данные по проекту"
        media={<SearchBar onChange={setSearch} />}
        className={styles.tab__header}
      />
      {isLoading ? (
        <Skeleton.List height={65} className={styles.skeleton} />
      ) : (
        <ScrollWrap height={"876px"}>
          <SourceDataContent
            activeTab={activeTab}
            data={data!}
            can={can}
            searchValue={searchValue}
          />
        </ScrollWrap>
      )}
    </div>
  );
};
