import cn from "classnames";
import { RequiredTypes } from "src/components/newUI/RequiredTypes/RequiredTypes";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import {
  PublishedObjectControllingEntities,
  PublishedObjectView,
} from "src/FSD/entities/publishedObject/model/controllingEntities";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import styles from "./Executor.module.scss";
import { useExecutorSearch } from "../../lib/hooks/useExecutorSearch";
import PublishedObjectModals from "../../../modals";

type Props = {
  publishedObject: PublishedObjectView;
  type: PublishedObjectControllingEntities;
};

export const ExecutorSearch = ({ publishedObject, type }: Props) => {
  const {
    countApplications,
    handleNavigation,
    requestExecutionHeader,
    isFetchingApplications,
  } = useExecutorSearch({ publishedObject, type });

  const { name, handleOpenModal, handleCloseModal } = useModal();

  return (
    <>
      <Modal
        isActive={name === ModalNames.PublishedObject.CREATE_REQUEST}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.CreateRequestForm
          id={publishedObject.id}
          header={requestExecutionHeader}
          type={type}
        />
      </Modal>
      <Card
        header={<Card.Header>Исполнитель</Card.Header>}
        className={cn(styles.executor, styles.executorCard)}
        controls={
          <Card.Controls location="top">
            {publishedObject.can!.requestExecution && (
              <Button
                onClick={() => {
                  handleOpenModal({
                    name: ModalNames.PublishedObject.CREATE_REQUEST,
                  });
                }}
                color="primary"
              >
                Заявиться
              </Button>
            )}
          </Card.Controls>
        }
      >
        <div className={styles.userInfo}>
          <Paragraph>Требуемая форма собственности</Paragraph>
          <RequiredTypes
            types={publishedObject.executorType}
            classname={styles.executor_type}
          />
        </div>
        <div className={styles.applications}>
          {isFetchingApplications ? (
            <Skeleton.List width={"90px"} height={50} count={1} />
          ) : (
            countApplications > 0 && (
              <>
                <div
                  className={styles.applications__count}
                  onClick={handleNavigation}
                >
                  {countApplications}
                </div>
                <p>Кол-во заявок</p>
              </>
            )
          )}
        </div>
        <hr className={styles.yellowLine} />
      </Card>
    </>
  );
};
