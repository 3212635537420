import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../../../../app.cofig";
import { httpRequestGet, httpRequestPost } from "../../../services/api/api";
import { request } from "../../../services/api/requestHandler";
import { pushError } from "../../errorTrace";

const ApiGetTasks = (category: string): Promise<Response> =>
  httpRequestGet(`${config.localDomain}/v1/project-process/${category}`);

const ApiGetTasksByUserType = (
  sectionId: number,
  userType: number
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/process-work/index?expand=countAccesses,executorNds,activeAccess.user,activeAccess.userType&ProcessWorkSearch[process_id]=${sectionId}&ProcessWorkSearch[userTypeId]=${userType}`
  );

const ApiGetCustomerAlign = (sectionId: number): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/project-process/start-request-customer-align?id=${sectionId}&expand=svgIcon`
  );

const ApiGetDocumentations = (sectionId: number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project-process/get-documentations?id=${sectionId}&expand=svgIcon`
  );

const getWorks = async (
  category: string,
  dispatch: any,
  ignoreError?: boolean
) => {
  let tasks: any = [];
  await request(
    ApiGetTasks(category),
    (response) => {
      tasks = response;
    },
    () => (err) => {
      if (ignoreError) {
        return;
      }

      dispatch(pushError(err));
      throw Error();
    }
  )();

  return tasks;
};

export const getInTasks = createAsyncThunk(
  "dashboard/getInTasks",
  async (id: number, { dispatch }) => {
    const tasks = await getWorks(`get-in-tasks?id=${id}`, dispatch);
    return tasks;
  }
);

export const getOutTasks = createAsyncThunk(
  "dashboard/getOutTasks",
  async (id: number, { dispatch }) => {
    const tasks = await getWorks(`get-out-tasks?id=${id}`, dispatch);
    return tasks;
  }
);

export const getSubCheckTasks = createAsyncThunk(
  "dashboard/getSubCheckTasks",
  async (id: number, { dispatch }) => {
    const tasks = await getWorks(
      `get-checks?id=${id}&expand=taskToProcess.projectPart,projectProcess,taskProcess`,
      dispatch,
      true
    );
    return tasks;
  }
);

export const getTasksByUserType = createAsyncThunk(
  "dashboard/getTasksByUserType",
  async (
    { sectionId, userType }: { sectionId: number; userType: number },
    { dispatch }
  ) => {
    let tasks: any = [];
    await request(
      ApiGetTasksByUserType(sectionId, userType),
      (response) => {
        tasks = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return tasks;
  }
);

export const getCustomerAlign = createAsyncThunk(
  "dashboard/getCustomerAlign",
  async (sectionId: number, { dispatch }) => {
    let tasks: any = [];
    await request(
      ApiGetCustomerAlign(sectionId),
      (response) => {
        tasks = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return tasks;
  }
);

export const getDocumentations = createAsyncThunk(
  "dashboard/getDocumentations",
  async (sectionId: number, { dispatch }) => {
    let tasks: any = [];
    await request(
      ApiGetDocumentations(sectionId),
      (response) => {
        tasks = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return tasks;
  }
);
