import { useState } from "react";
import { useTabsPanelContext } from "src/FSD/widgets/v2/TabsPanel";
import { useProjectProcessGetFilesDtoQuery } from "src/FSD/entities/projectProcess/api";
import { tabMapping } from "../model/tabMapping";

type TProps = {
  id: number;
};

export const useSourceData = ({ id }: TProps) => {
  const { currentChildTab } = useTabsPanelContext();
  const activeTab = tabMapping[currentChildTab];
  const [search, setSearch] = useState<string>();

  const { data, isFetching } = useProjectProcessGetFilesDtoQuery({
    projectProcessId: id,
    checklistName: search,
  });

  const isLoading = isFetching || !data;

  return {
    activeTab,
    isLoading,
    searchValue: search,
    setSearch,
    data,
  };
};
