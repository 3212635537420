import { countJobDuration } from "src/FSD/shared/lib/helpers/countJobDuration";
import { formFullUserName } from "src/FSD/shared/lib/helpers";
import { PublishedObjectView } from "src/FSD/entities/publishedObject/model/controllingEntities";

export const useExecutorHave = (publishedObject: PublishedObjectView) => {
  const executor = publishedObject.activeAccess.user!;
  const { chatId } = publishedObject.project!;

  const showOptions =
    publishedObject.can!.requestRemoveExecutor ||
    publishedObject.can!.updateExecutor ||
    publishedObject.activeAccess.taskRemove?.can?.complete ||
    publishedObject.task.can!.feedbackToExecutor;

  const fullName = formFullUserName(executor);

  const jobDuration = countJobDuration(executor.job_duration);

  return {
    fullName,
    executor,
    chatId,
    showOptions,
    jobDuration,
  } as const;
};
