import { useProjectProcessGetSubCheckTasksQuery } from "src/FSD/entities/projectProcess/api";

export const useCheckTasks = (projectProcessId: number) => {
  const { data: subCheckTasks = [], isFetching } =
    useProjectProcessGetSubCheckTasksQuery({
      id: projectProcessId,
    });

  const isTasksLoading = isFetching || !subCheckTasks;

  return { subCheckTasks, isTasksLoading } as const;
};
