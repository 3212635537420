import React, { memo } from "react";
import { TabChildrenHeader } from "src/FSD/widgets/v2/TabsPanel";
import { ExpertiseDocumentsHeader } from "src/FSD/widgets/v2/projectProcess/std/ExpertiseDocuments/ui/ExpertiseDocumentsHeader";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { useExpertiseDocuments } from "src/FSD/widgets/v2/projectProcess/std/ExpertiseDocuments/lib/hooks/useExpertiseDocuments";
import { DocumentCard } from "./DocumentCard";
import styles from "./ExpertiseDocuments.module.scss";

type TProps = {
  can: ApiProjectProcessResponses.View["can"];
  projectProcessId: number;
};

export const ExpertiseDocuments = memo(({ can, projectProcessId }: TProps) => {
  const { data, isLoading } = useExpertiseDocuments({ projectProcessId });

  return (
    <div className={styles.documents}>
      {isLoading ? (
        <Skeleton.List height={70} />
      ) : (
        <>
          <TabChildrenHeader
            title="Договоры"
            media={
              <ExpertiseDocumentsHeader
                projectProcessId={projectProcessId}
                can={can}
              />
            }
            className={styles.header}
          />
          {data.map((document) => (
            <DocumentCard key={document.id} can={can} {...document} />
          ))}
        </>
      )}
    </div>
  );
});
