import { useTaskUpdateDateMutation } from "src/FSD/entities/task/api";
import { PublishedObjectView } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { stringDateToDDMMYYYY } from "src/FSD/shared/lib/helpers";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { Nullable } from "../../../../../../../types/generics";

type TDates = {
  date: Nullable<Date>;
};

export const useDeadlines = (publishedObject: PublishedObjectView) => {
  const [updateDate, { isLoading: isDatesUpdating }] =
    useTaskUpdateDateMutation();

  const canEditDates =
    Boolean(publishedObject.task) && publishedObject.can!.update;

  const factDateCreate = publishedObject?.task?.date_create
    ? stringDateToDDMMYYYY(publishedObject.task.date_create)
    : "";

  const factDateEnd =
    publishedObject?.task?.date_deadline || publishedObject?.task?.date_complete
      ? stringDateToDDMMYYYY(
          publishedObject.task.date_complete ||
            publishedObject.task.date_deadline
        )
      : "";

  const handleChangeDates = async (dataValues: TDates) => {
    if (!publishedObject.task) {
      return;
    }

    const data = {
      date_deadline: toServerDate(dataValues.date),
    };
    await updateDate({ id: publishedObject.task.id, data });
  };

  return {
    canEditDates,
    handleChangeDates,
    isDatesUpdating,
    factDateCreate,
    factDateEnd,
  } as const;
};
