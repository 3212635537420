import React, { memo } from "react";
import { useSelector } from "react-redux";
import { ProjectProcessSourceDataChild } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessInitialDataTypes";
import { useAppSelector } from "src/app/store";
import { getProjectInitialData } from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import {
  getProjectProcessInfoProjectId,
  getProjectProcessInfoProjectParentId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getCanProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { SourceDataView } from "src/FSD/widgets/v2/project/SourceData/ui/SourceDataView/SourceDataView";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { useTaskUpdateDateMutation } from "src/FSD/entities/task/api";

type SourceDataCardProps = ProjectProcessSourceDataChild & {
  isMobile?: boolean;
  parentId: number;
  index: number;
  canAddFile: boolean;
};

export const SourceDataCard = memo(
  ({
    status,
    isMobile,
    checklist,
    date_deadline,
    date_create,
    critical,
    date_complete,
    can,
    task_id,
    id,
    parentId,
    index,
    canAddFile,
  }: SourceDataCardProps) => {
    const { isLoadDateUpdate } = useSelector(getProjectInitialData);
    const projectId = useAppSelector(getProjectProcessInfoProjectId);
    const projectParentId = useAppSelector(
      getProjectProcessInfoProjectParentId
    );
    const canProjectProcess = useAppSelector(getCanProcessInfo);

    const [updateDate] = useTaskUpdateDateMutation();

    const onChangeDate = async (data: any) => {
      const dataParams = {
        date_create: toServerDate(data.startDate),
        date_deadline: toServerDate(data.endDate),
      };
      await updateDate({ id: task_id, data: dataParams });
    };

    if (projectId) {
      return (
        <SourceDataView
          isMobile={isMobile}
          isLoadDateUpdate={isLoadDateUpdate}
          status={status}
          checklist={checklist}
          date_deadline={date_deadline}
          date_create={date_create}
          date_complete={date_complete}
          critical={critical}
          can={can}
          task_id={task_id}
          onChangeDate={onChangeDate}
          canUpdateSourceData={canProjectProcess?.updateSourceData || false}
          canAddFile={canAddFile}
          id={id}
          projectId={projectId}
          parentId={parentId}
          projectParentId={projectParentId}
          index={index}
          type={"process"}
        />
      );
    }
    return <></>;
  }
);
