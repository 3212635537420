import React from "react";
import { Button, Modal } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";

type TProps = {
  projectProcessId: number;
  can: ApiProjectProcessResponses.View["can"];
};

export const ExpertiseConclusionHeader = ({
  projectProcessId,
  can,
}: TProps) => {
  const { name, handleCloseModal, handleOpenModal } = useModal();

  const handleOpenConclusionCreate = () => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.CONCLUSION_CREATE,
    });
  };

  if (can!.execute) {
    return (
      <div>
        <Modal
          isActive={name === ModalNames.ProjectProcess.CONCLUSION_CREATE}
          handleClose={handleCloseModal}
        >
          <ProjectProcessModals.ConclusionCreateForm
            processId={projectProcessId}
          />
        </Modal>
        <Button onClick={handleOpenConclusionCreate} color="primary">
          Выполнить
        </Button>
      </div>
    );
  }

  return null;
};
