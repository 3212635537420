import { useProjectProcessViewQuery } from "src/FSD/entities/projectProcess/api";
import { useDevice } from "src/FSD/shared/lib/hooks/useDevice";
import { getBreadcrumbs } from "../helpers/getBreadcrumbs";
import { useDataMapper } from "../../../../../shared/lib/hooks/useDataMapper";
import { mapProjectProcess } from "../mappers/mapProjectProcess";

type TUsePageProps = {
  projectProcessId: number;
};

const MOBILE_SIZE = 1280;
const TABLET_SIZE = 1600;

export const useProjectProcessViewPage = ({
  projectProcessId,
}: TUsePageProps) => {
  const {
    data: projectProcessRaw,
    isFetching,
    isLoading,
  } = useProjectProcessViewQuery(
    { id: projectProcessId },
    { skip: !projectProcessId }
  );

  const projectProcess = useDataMapper(projectProcessRaw, mapProjectProcess);

  const device = useDevice(MOBILE_SIZE, TABLET_SIZE);

  const breadcrumbs = getBreadcrumbs(projectProcess);

  const isProjectProcessLoading = !projectProcess || isFetching;

  return {
    isProjectProcessLoading,
    breadcrumbs,
    isLoading,
    device,
    projectProcess,
  } as const;
};
