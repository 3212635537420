import React, { memo, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useProjectSourceDataIndexQuery } from "src/FSD/entities/projectSourceData/api";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectProcessOpenSourceData } from "src/app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessSourceChild";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import { CircularIndeterminate } from "src/components/UI/components/Project/Skeleton";
import { setOpenSourceData } from "src/app/feature/ProjectProcessView/InitialData/slice/projectProcessInitialData";
import { messages } from "src/components/pages/Project/View/InitialData/InitialDataCard";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { CardHeader } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/CardHeader/CardHeader";
import { newIconBtnSx } from "src/styles/restyle";
import { SourceDataCard } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/SourceDataCard/SourceDataCard";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ApiPrimaryProjectType } from "src/types/api/primaryTypes/apiPrimaryProjectType";
import { useProjectCheckListDeleteMutation } from "src/FSD/entities/projectChecklist/api";
import { GearFill } from "react-bootstrap-icons";
import styles from "./TabsSourceData.module.scss";

type SourceDataType = {
  all: number;
  inWork: number;
  dot: boolean;
  name: string;
  searchValue?: string;
  parentId: number;
  projectId: number;
  can: ApiPrimaryProjectType["can"];
};

export const TabsSourceData = memo(
  ({
    name,
    parentId,
    projectId,
    index,
    all,
    inWork,
    dot,
    type,
    can,
    searchValue,
  }: SourceDataType & { index: number; type: string | number }) => {
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile(681);

    const openSourceData = useAppSelector(getProjectProcessOpenSourceData);

    const isOpen = !Number.isNaN(Number(openSourceData[index]));

    const [modalGear, setModalGear] = useState(false);

    const canViewProject = can.view;
    const canAddFile = can.editByGipHelper || false;

    const [skip, setSkip] = useState(!isOpen);
    const { data: sourceChildItems, isLoading } =
      useProjectSourceDataIndexQuery(
        {
          id: projectId,
          parentId,
          type: Number(type),
          searchValue,
        },
        { skip }
      );

    useEffect(() => {
      if (isOpen && !sourceChildItems) {
        setSkip(false);
      }
    }, [isOpen]);

    const accordionClick = (index: number) => {
      dispatch(setOpenSourceData(index));
    };

    const gearBtnClick = () => {
      setModalGear(true);
    };

    const [deleteProjectCheckList] = useProjectCheckListDeleteMutation();

    const handleDeleteSourceDataName = async (sourceDataId: number) => {
      if (!window.confirm(messages.CONFIRM_DELETE_SOURCE_ITEM)) {
        return;
      }

      await deleteProjectCheckList({ id: sourceDataId });
    };

    return (
      <>
        <div>
          <Modal
            isActive={modalGear}
            handleClose={() => {
              setModalGear(false);
            }}
          >
            <ProjectProcessModals.ModalGear
              type={Number(type)}
              parentId={parentId}
              projectId={projectId}
              name={name}
              can={can}
              handleDeleteSourceDataName={handleDeleteSourceDataName}
            />
          </Modal>
          <CardHeader
            title={name}
            expanded={isOpen}
            inWork={inWork}
            all={all}
            dot={dot}
            onClick={() => accordionClick(index)}
          />
        </div>
        {isOpen && (
          <>
            <div className={styles.container}>
              {!isMobile && (
                <div className={styles.parent}>
                  <div>Статус</div>
                  <div>Наименование</div>
                  <div>Дата запроса</div>
                  <div>Контр. срок</div>
                  <div>Согласовано</div>
                  <div className={styles.parent__icon}>
                    {canViewProject && (
                      <IconButton
                        sx={{ ...newIconBtnSx, padding: 0 }}
                        onClick={gearBtnClick}
                      >
                        <GearFill size={22} />
                      </IconButton>
                    )}
                  </div>
                  {sourceChildItems &&
                    sourceChildItems.map((child) => (
                      <SourceDataCard
                        key={child.id}
                        {...child}
                        parentId={parentId}
                        index={index}
                        canAddFile={canAddFile}
                      />
                    ))}
                </div>
              )}
              {isMobile &&
                sourceChildItems &&
                sourceChildItems.map((child) => (
                  <SourceDataCard
                    key={child.id}
                    {...child}
                    isMobile={isMobile}
                    parentId={parentId}
                    index={index}
                    canAddFile={canAddFile}
                  />
                ))}
              {!sourceChildItems && isLoading && (
                <div className={styles.container}>
                  <CircularIndeterminate />
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
);
