import { useDocumentIndexQuery } from "src/FSD/entities/expertise/document/api";

type TPops = {
  projectProcessId: number;
};

export const useExpertiseDocuments = ({ projectProcessId }: TPops) => {
  const { data = [], isFetching } = useDocumentIndexQuery(
    {
      id: projectProcessId,
      expand: ["file"],
    },
    { skip: !projectProcessId }
  );

  const isLoading = isFetching || !data;

  return { data, isLoading } as const;
};
