import MenuItem from "@mui/material/MenuItem";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { ThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import cn from "classnames";
import { CheckLabel } from "src/FSD/shared/uiKit/v2/CheckIcon";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { Modal } from "src/FSD/shared/uiKit/v2";
import {
  PublishedObjectControllingEntities,
  PublishedObjectView,
} from "src/FSD/entities/publishedObject/model/controllingEntities";
import styles from "./Requirements.module.scss";
import { useRequirements } from "../../lib/hooks/useRequirements";
import PublishedObjectModals from "../../../modals";

type Props = {
  publishedObject: PublishedObjectView;
  type: PublishedObjectControllingEntities;
};

const namesByEntity = {
  ProcessWork: "задаче",
  ProjectProcess: "разделу",
};

export const Requirements = ({ publishedObject, type }: Props) => {
  const { threeDotButtonRef, showOptions, closeThreeDot } =
    useRequirements(publishedObject);

  const { name, handleOpenModal, handleCloseModal } = useModal();

  const entityName = namesByEntity[type];

  return (
    <>
      <Modal
        isActive={
          name === ModalNames.PublishedObject.ADDITIONAL_REQUIREMENTS_UPDATE
        }
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.AdditionalRequirementsUpdateForm
          id={publishedObject.id}
          header={`Дополнительные требования к ${entityName}`}
          type={type}
        />
      </Modal>
      <Card
        header={<Card.Header>Требования к {entityName}</Card.Header>}
        controls={
          <Card.Controls location="top">
            {showOptions && (
              <ThreeDotButton ref={threeDotButtonRef} color="success">
                <MenuItem
                  onClick={() => {
                    closeThreeDot();
                    handleOpenModal({
                      name: ModalNames.PublishedObject
                        .ADDITIONAL_REQUIREMENTS_UPDATE,
                    });
                  }}
                  disableRipple
                >
                  Редактировать
                </MenuItem>
              </ThreeDotButton>
            )}
          </Card.Controls>
        }
      >
        {publishedObject.projectPart.published && (
          <div className={styles.checksList}>
            <div
              className={cn(
                styles.checksList__column,
                styles.checksList__column_short
              )}
            >
              <h3>Договор</h3>
              <CheckLabel
                isCheck={publishedObject.secure}
                text="Безопасная сделка"
                className={styles.checksList__check}
                fontSize={20}
              />
              <CheckLabel
                isCheck={publishedObject.with_docs}
                text="Заключение договора"
                className={styles.checksList__check}
                fontSize={20}
              />
            </div>
            <div className={styles.checksList__column}>
              <h3>Разработка</h3>
              <h3 />
              <CheckLabel
                isCheck={publishedObject.bim}
                text="BIM"
                className={styles.checksList__check}
                fontSize={20}
              />
              <CheckLabel
                isCheck={publishedObject.vor}
                text="Ведомость объемов работ"
                className={styles.checksList__check}
                fontSize={20}
              />
              <CheckLabel
                isCheck={publishedObject.kac}
                text="Конъюнктурный анализ цен"
                className={styles.checksList__check}
                fontSize={20}
              />
              <CheckLabel
                isCheck={publishedObject.vor_pnr}
                text="ВОР на пусконаладочные работы"
                className={styles.checksList__check}
                fontSize={20}
              />
            </div>
          </div>
        )}
        <hr className={styles.yellowLine} />
      </Card>
    </>
  );
};
