import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import {
  ThreeDotButton,
  useThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useProcessWorkUnPublishMutation } from "src/FSD/entities/processWork/api";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import PublishedObjectModals from "../../../../widgets/v2/publishedObject/modals";
import TaskModals from "../../../../widgets/v2/task/modals";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const Actions = ({ processWork }: Props) => {
  const [unpublishWork] = useProcessWorkUnPublishMutation();

  const { closeThreeDot, threeDotButtonRef } = useThreeDotButton();

  const { name, handleOpenModal, handleCloseModal, restData } = useModal();

  const projectProcessName = `«${processWork.projectPart.name}${processWork.projectPart.code ? ` (${processWork.projectPart.code})` : ""}»`;

  const processWorkName = `«${processWork.title}» по разделу ${projectProcessName}`;

  const publishObjectHeader = `Публикация задачи «${processWork?.title}» по проекту ${projectProcessName}`;

  const headerUpdate = `Редактирование задачи ${processWorkName}`;

  const handleUnpublishWork = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть задачу с публикации?"
    );

    if (result) {
      await unpublishWork({ id: processWork.id });
    }
  };

  return (
    <>
      <Modal
        isActive={name === ModalNames.PublishedObject.PUBLISH_OBJECT}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.PublishObjectForm
          id={processWork.id}
          header={publishObjectHeader}
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.UpdatePublishedObjectForm
          id={processWork.id}
          header={restData.header}
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.Task.FEEDBACK}
        handleClose={handleCloseModal}
      >
        <TaskModals.FeedbackForm
          taskId={restData.taskId}
          header={restData.header}
          isExecutor={restData.isExecutor}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.FREEZE_FUNDS}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.FreezeFundsForm
          id={processWork.id}
          header="Пополнение баланса задачи"
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <ThreeDotButton ref={threeDotButtonRef}>
        {processWork.can!.publish && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              handleOpenModal({
                name: ModalNames.PublishedObject.PUBLISH_OBJECT,
              });
            }}
          >
            Опубликовать
          </MenuItem>
        )}
        {processWork.can!.unpublish && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              handleOpenModal({
                name: ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT,
                header: headerUpdate,
              });
            }}
          >
            Редактировать
          </MenuItem>
        )}
        {processWork.can!.unpublish && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              handleUnpublishWork();
            }}
          >
            Вернуть с публикации
          </MenuItem>
        )}
        {processWork.can!.freezeFunds && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              handleOpenModal({
                name: ModalNames.PublishedObject.FREEZE_FUNDS,
              });
            }}
          >
            Пополнить баланс
          </MenuItem>
        )}
        {processWork.task?.can?.feedbackToController && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              handleOpenModal({
                name: ModalNames.Task.FEEDBACK,
                taskId: processWork.task.id,
                header: "Оценить заказчика",
                isExecutor: false,
              });
            }}
          >
            Оценить заказчика
          </MenuItem>
        )}
        {processWork.can!.chat && (
          <MenuItem
            to={`/chat/${processWork.project!.chatId}`}
            target="_blank"
            component={Link}
          >
            Чат
          </MenuItem>
        )}
      </ThreeDotButton>
    </>
  );
};
