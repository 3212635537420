import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiTaskResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  processId: number;
}>;

export const getStopped = (builder: QueryBuilder) =>
  builder.query<ApiTaskResponses.View[], TParams>({
    query: ({ processId }) => ({
      url: `v1/task/get-stopped`,
      params: {
        processId,
      },
    }),
    providesTags: [ApiTags.Task.GET_STOPPED],
  });
