import { useMemo, useCallback } from "react";
import { useResolution } from "src/FSD/shared/lib/hooks/useResolution";
import { getNdsText } from "src/FSD/shared/lib/helpers/ndsText";
import {
  PublishedObjectControllingEntities,
  PublishedObjectView,
} from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useProcessWorkUpdatePriceMutation } from "src/FSD/entities/processWork/api";
import { useProjectProcessUpdatePriceMutation } from "src/FSD/entities/projectProcess/api";
import { Colors } from "src/FSD/shared/uiKit/v2/Pie";

const updatePriceMutations: Record<PublishedObjectControllingEntities, any> = {
  ProcessWork: useProcessWorkUpdatePriceMutation,
  ProjectProcess: useProjectProcessUpdatePriceMutation,
};

type HookProps = {
  publishedObject: PublishedObjectView;
  type: PublishedObjectControllingEntities;
};

export const usePayment = ({ publishedObject, type }: HookProps) => {
  const isMobile = useResolution(560);

  const [updatePrice, { isLoading: isPriceUpdating }] =
    updatePriceMutations[type]();

  const pieChartDataSet = useMemo(() => {
    const dataSet = [
      {
        payPart: publishedObject.pay1,
        colorHex: Colors.PRIMARY,
      },
      {
        payPart: publishedObject.pay2,
        colorHex: Colors.SECONDARY,
      },
    ];

    if (publishedObject.pay3) {
      dataSet.push({
        payPart: publishedObject.pay3,
        colorHex: Colors.TERTIARY,
      });
    }

    return dataSet;
  }, [publishedObject]);

  const ndsText = getNdsText(Boolean(publishedObject.executor_nds));

  const canEditPrice =
    Boolean(publishedObject.task) && publishedObject.can!.update;

  const handleChangePrice = useCallback(async (value: string) => {
    const data = {
      price: Number(value),
    };

    await updatePrice({ id: publishedObject.id, data });
  }, []);

  return {
    pieChartDataSet,
    ndsText,
    isPriceUpdating,
    handleChangePrice,
    canEditPrice,
    isMobile,
  } as const;
};
