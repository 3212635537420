import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { Deadlines } from "../../../publishedObject/Info/ui/Deadlines/Deadlines";
import { Payment } from "../../../publishedObject/Info/ui/Payment/Payment";
import { Requirements } from "../../../publishedObject/Info/ui/Requirements/Requirements";
import { Executor } from "../../../publishedObject/Info/ui/Executor/Executor";
import styles from "./Info.module.scss";
import { Header } from "./ui/Header";
import { Warnings } from "./ui/Warning/Warnings";
import { SourcesFiles } from "./ui/SourceFiles/SourcesFiles";

type Props = {
  projectProcess: ApiProjectProcessResponses.View;
};

export const Info = ({ projectProcess }: Props) => {
  const isExpertise = projectProcess.projectPart.type.key === Statuses.ACCEPT;

  return (
    <>
      <Header projectProcess={projectProcess} />
      {projectProcess.warning && (
        <Warnings
          warning={projectProcess.warning}
          projectProcessId={projectProcess.id}
        />
      )}
      <div className={styles.grid}>
        <Deadlines publishedObject={projectProcess} />
        <Payment
          publishedObject={projectProcess}
          type={PublishedObjectControllingEntities.PROJECT_PROCESS}
        />
        {!isExpertise && (
          <>
            <Executor
              publishedObject={projectProcess}
              type={PublishedObjectControllingEntities.PROJECT_PROCESS}
              accessType={ProjectAccessControllingEntities.PROJECT_PROCESS}
            />
            <Requirements
              publishedObject={projectProcess}
              type={PublishedObjectControllingEntities.PROJECT_PROCESS}
            />
          </>
        )}
      </div>
      <SourcesFiles files={projectProcess.files} />
    </>
  );
};
