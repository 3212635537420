import {
  Dot,
  Heading,
  Paragraph,
  TextLink,
  Uploader,
} from "src/FSD/shared/uiKit/v2";
import {
  formFullUserName,
  stringDateToDDMMYYYY,
} from "src/FSD/shared/lib/helpers";
import { daysFromToday } from "src/FSD/shared/lib/helpers/daysFromToday";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import cn from "classnames";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { finalStatuses } from "src/FSD/shared/lib/constants/finalStatuses";
import styles from "./CheckTasks.module.scss";

export type Props = ApiProjectProcessResponses.GetSubCheckTasks[0]["tasks"][0];

export const Details = (task: Props) => {
  const {
    projectName,
    dot,
    fileSize,
    task: {
      id,
      status,
      comment,
      files,
      name,
      date_complete,
      date_deadline,
      executor,
      taskProcess,
    },
  } = task;

  const { code } = taskProcess!.projectPart;

  const beforeText = status.key === Statuses.WORK ? "до" : "";

  const formedComment = comment || "Комментарий отсутствует";

  const formedFileSize = fileSize.join("");

  const fullExecutorName = formFullUserName(executor);

  const formedFiles = files.slice(0, 4);

  const dateDeadline = date_complete || date_deadline;

  const daysDiffernce = daysFromToday(dateDeadline);

  const formedDate = stringDateToDDMMYYYY(dateDeadline);

  const isDateExpired =
    daysDiffernce < 0 && !finalStatuses.includes(status.key);

  const { handleOpenModal } = useModal();

  return (
    <div className={styles.wrapper}>
      <div className={styles.details}>
        <div className={styles.details__title}>
          <Heading className={styles.details__title__process}>
            {dot && (
              <Dot
                dot="warning"
                className={styles.details__title__process__dot}
              />
            )}
            <span>{name}</span>
          </Heading>
          <Paragraph>
            {code} / {projectName}
          </Paragraph>
        </div>
        <Paragraph className={styles.details__executor}>
          {fullExecutorName}
        </Paragraph>
        <div className={styles.details__status}>
          <Heading>{status.value}</Heading>
          <Paragraph
            className={cn(
              styles.details__status__date,
              isDateExpired && styles.details__status__date_expired
            )}
          >
            {beforeText} {formedDate}
          </Paragraph>
        </div>
      </div>
      <div className={styles.details__comment}>
        <Heading className={styles.details__comment__header}>
          Комментарий
        </Heading>
        <Paragraph>{formedComment}</Paragraph>
        <div
          onClick={() =>
            handleOpenModal({
              name: ModalNames.Task.COMMENTS,
              taskId: task.task.id,
            })
          }
        >
          <Paragraph className={styles.link}>История комментариев</Paragraph>
        </div>
      </div>
      <div className={styles.details__files}>
        <Heading>Вложения ({files.length})</Heading>
        <Uploader
          // @ts-ignore
          initialFiles={formedFiles}
          canEdit={false}
          weakStretch
          className={styles.details__files__list}
        />
        <TextLink
          className={styles.link}
          url={`/task/download-files?id=${id}`}
          target="_blank"
          redirect
        >
          Скачать все ({formedFileSize})
        </TextLink>
      </div>
    </div>
  );
};
