import { useThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton/useThreeDotButton";
import { PublishedObjectView } from "src/FSD/entities/publishedObject/model/controllingEntities";

export const useRequirements = (publishedObject: PublishedObjectView) => {
  const { threeDotButtonRef, closeThreeDot } = useThreeDotButton();

  const { kac, vor, vor_pnr, published } = publishedObject.projectPart;

  const isAdditionalRequirements = kac || vor || vor_pnr;

  const showOptions =
    publishedObject.can!.update && published && isAdditionalRequirements;

  return {
    threeDotButtonRef,
    showOptions,
    closeThreeDot,
  } as const;
};
