import { HTTPMethods } from "src/FSD/shared/api/common";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { Nullable } from "../../../../../types/generics";

type TParams = RequestParams<{
  id: number;
  data: {
    date_create?: Nullable<string>;
    date_deadline: Nullable<string>;
  };
}>;

export const updateDate = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/task/update-date?id=${id}`,
      method: HTTPMethods.PUT,
      data,
    }),
    invalidatesTags: [
      ApiTags.ProcessWork.VIEW,
      ApiTags.ProjectProcess.VIEW,
      ApiTags.ProjectProcess.GET_OUT_TASKS,
    ],
  });
