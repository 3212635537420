import { ApiProcessWorkResponses } from "../../processWork/model/responseTypes";
import { ApiProjectProcessResponses } from "../../projectProcess/model/responseTypes";

export type PublishedObjectView =
  | ApiProjectProcessResponses.View
  | ApiProcessWorkResponses.View;

export enum PublishedObjectControllingEntities {
  PROJECT_PROCESS = "ProjectProcess",
  PROCESS_WORK = "ProcessWork",
}
