import { memo } from "react";
import classNames from "classnames";
import styles from "./Price.module.scss";

const contractPrice = "Договорная";

function priceTrimmed(price: string) {
  if (price) {
    return price.slice(0, -3).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
  }
  return contractPrice;
}

type Props = {
  price: string;
  className?: string;
  isProcess?: boolean;
};

export const Price = memo(({ price, className, isProcess = false }: Props) => {
  const priceTrim = priceTrimmed(price);
  const isTreaty = Number(price?.split(".")[0]);
  const priceComparison = isTreaty ? priceTrim : contractPrice;
  const canLowFontSizeRuble = isProcess && !priceTrim;

  return (
    <div className={classNames(styles.price, className)}>
      <p
        className={classNames(
          styles.price__rub,
          canLowFontSizeRuble && styles.price__lowRub
        )}
      >
        {priceComparison} {isTreaty ? <span>₽</span> : ""}
      </p>
      <p className={styles.price__nds}>{!isTreaty && "без налога (НДС)"}</p>
    </div>
  );
});
