import { rootApi } from "src/FSD/shared/api/common/rootApi";
import {
  application,
  canCheck,
  createOutTask,
  delegate,
  depositFunds,
  freezeFunds,
  getDocTree,
  getDocumentations,
  getInTasks,
  getOutTasks,
  getProjectProcessTree,
  getUserTypeToAssign,
  getUsersToAssign,
  getWorks,
  publish,
  publishInfo,
  removed,
  startCustomerAlign,
  startRequestCustomerAlign,
  toProcesses,
  toProjects,
  updateAddition,
  requestOutTask,
  getSubCheckTasks,
  updatePrice,
  updatePublished,
  view,
  getFilesDto,
  pause,
  unPublish,
} from "./queries";

export const projectProcessApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    projectProcessApplication: application(builder),
    projectProcessCanCheck: canCheck(builder),
    projectProcessCreateOutTask: createOutTask(builder),
    projectProcessDelegate: delegate(builder),
    projectProcessDepositFunds: depositFunds(builder),
    projectProcessFreezeFunds: freezeFunds(builder),
    projectProcessGetDocTree: getDocTree(builder),
    projectProcessGetDocumentations: getDocumentations(builder),
    projectProcessGetInTasks: getInTasks(builder),
    projectProcessGetOutTasks: getOutTasks(builder),
    projectProcessGetProjectProcessTree: getProjectProcessTree(builder),
    projectProcessGetUserTypeToAssign: getUserTypeToAssign(builder),
    projectProcessGetUsersToAssign: getUsersToAssign(builder),
    projectProcessGetWorks: getWorks(builder),
    projectProcessPublish: publish(builder),
    projectProcessPublishInfo: publishInfo(builder),
    projectProcessRemoved: removed(builder),
    projectProcessRequestOutTask: requestOutTask(builder),
    projectProcessStartCustomerAlign: startCustomerAlign(builder),
    projectProcessStartRequestCustomerAlign: startRequestCustomerAlign(builder),
    projectProcessToProcesses: toProcesses(builder),
    projectProcessToProjects: toProjects(builder),
    projectProcessUpdateAddition: updateAddition(builder),
    projectProcessUpdatePrice: updatePrice(builder),
    projectProcessUpdatePublished: updatePublished(builder),
    projectProcessView: view(builder),
    projectProcessGetSubCheckTasks: getSubCheckTasks(builder),
    projectProcessGetFilesDto: getFilesDto(builder),
    projectProcessPause: pause(builder),
    projectProcessUnpublish: unPublish(builder),
  }),
  overrideExisting: false,
});

export const {
  useLazyProjectProcessGetUsersToAssignQuery,
  useLazyProjectProcessToProcessesQuery,
  useProjectProcessApplicationQuery,
  useProjectProcessCanCheckQuery,
  useProjectProcessCreateOutTaskMutation,
  useProjectProcessDelegateMutation,
  useProjectProcessDepositFundsMutation,
  useProjectProcessFreezeFundsMutation,
  useProjectProcessGetDocTreeQuery,
  useProjectProcessGetDocumentationsQuery,
  useProjectProcessGetInTasksQuery,
  useProjectProcessGetOutTasksQuery,
  useProjectProcessGetProjectProcessTreeQuery,
  useProjectProcessGetUserTypeToAssignQuery,
  useProjectProcessGetWorksQuery,
  useProjectProcessPublishInfoQuery,
  useProjectProcessPublishMutation,
  useProjectProcessRemovedQuery,
  useProjectProcessRequestOutTaskMutation,
  useProjectProcessStartCustomerAlignMutation,
  useProjectProcessStartRequestCustomerAlignMutation,
  useProjectProcessToProcessesQuery,
  useProjectProcessToProjectsQuery,
  useProjectProcessUpdateAdditionMutation,
  useProjectProcessUpdatePriceMutation,
  useProjectProcessUpdatePublishedMutation,
  useProjectProcessViewQuery,
  useProjectProcessGetSubCheckTasksQuery,
  useProjectProcessGetFilesDtoQuery,
  useProjectProcessPauseMutation,
  useProjectProcessUnpublishMutation,
} = projectProcessApi;
