import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { TabsPanel } from "src/FSD/widgets/v2/TabsPanel";
import { Breadcrumbs } from "src/FSD/shared/uiKit/v2/Breadcrumbs";
import { useResolution } from "src/FSD/shared/lib/hooks/useResolution";
import {
  SourceData,
  Documents,
  ExchangeTasks,
  ExecutionTasks,
  Expertise,
  CheckTasks,
  Materials,
  Works,
  Applications,
  Milestone,
  Info,
  Removed,
  ExpertiseDocuments,
  RemarksExpertise,
  ExpertiseConclusion,
} from "src/FSD/widgets/v2/projectProcess/std";
import { PageWrapper } from "src/FSD/shared/uiKit/v2/PageWrapper/PageWrapper";
import { useProjectProcessViewPage } from "../lib/hooks/useProjectProcessViewPage";
import { SkeletonPage } from "../../processWork/ui/SkeletonPage";
import { ScreenResultions } from "../../processWork/lib/constants/screenResolutions";
import { Actions } from "./Actions";

export const ProjectProcessViewPage = () => {
  const isMobile = useResolution(ScreenResultions.MOBILE);
  const isTablet = useResolution(ScreenResultions.TABLET);

  const { id } = useParams();

  const { projectProcess, device, breadcrumbs, isProjectProcessLoading } =
    useProjectProcessViewPage({
      projectProcessId: Number(id),
    });

  const componentsList = useMemo(() => {
    if (!projectProcess) {
      return {};
    }

    return {
      info: <Info projectProcess={projectProcess} />,
      sourceFiles: <SourceData can={projectProcess.project.can} />,
      documents: <Documents />,
      exchangeTasks: <ExchangeTasks />,
      executionTask: (
        <ExecutionTasks
          projectProcessId={projectProcess.id}
          can={projectProcess.can}
        />
      ),
      expertiseInTasks: <Expertise />,
      checkTasks: <CheckTasks projectProcessId={projectProcess.id} />,
      materials: <Materials />,
      works: <Works />,
      applications: <Applications projectProcessId={projectProcess.id} />,
      removed: <Removed projectProcessId={projectProcess.id} />,
      stages: <Milestone />,
      expertiseDocuments: (
        <ExpertiseDocuments
          can={projectProcess.can}
          projectProcessId={projectProcess.id}
        />
      ),
      expertiseRemarks: <RemarksExpertise />,
      expertiseConclusion: (
        <ExpertiseConclusion
          can={projectProcess.can}
          projectProcessId={projectProcess.id}
        />
      ),
    };
  }, [projectProcess]);

  return (
    <PageWrapper>
      {isProjectProcessLoading ? (
        <SkeletonPage isMobile={isMobile} isTablet={isTablet} />
      ) : (
        <>
          <Breadcrumbs items={breadcrumbs} />
          <TabsPanel
            title={projectProcess.projectPart.name}
            subTitle={projectProcess.status.value}
            tabsList={projectProcess.tabs}
            componentsList={componentsList}
            device={device}
            actions={<Actions projectProcess={projectProcess} />}
          />
        </>
      )}
    </PageWrapper>
  );
};
