import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import {
  PublishedObjectControllingEntities,
  PublishedObjectView,
} from "src/FSD/entities/publishedObject/model/controllingEntities";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import { ExecutorApplied } from "./ExecutorApplied";
import { ExecutorSearch } from "./ExecutorSearch";
import { ExecutorEmpty } from "./ExecutorEmpty";

type Props = {
  publishedObject: PublishedObjectView;
  type: PublishedObjectControllingEntities;
  accessType: ProjectAccessControllingEntities;
};

export const Executor = ({ publishedObject, type, accessType }: Props) => {
  if (publishedObject.activeAccess?.user) {
    return (
      <ExecutorApplied
        publishedObject={publishedObject}
        accessType={accessType}
        type={type}
      />
    );
  }

  if (publishedObject.status.key === Statuses.WORK) {
    return <ExecutorSearch publishedObject={publishedObject} type={type} />;
  }

  return <ExecutorEmpty />;
};
