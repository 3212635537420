import { Button, Modal, TextField } from "src/FSD/shared/uiKit/v2";
import styles from "./Stop.module.scss";
import { useStopProcess } from "../lib/hooks/useStopProcess";
import { StopReason } from "./StopReason";

type Props = {
  projectProcessId: number;
};

export const StopProcessForm = ({ projectProcessId }: Props) => {
  const {
    handleSubmit,
    register,
    errors,
    isSubmitting,
    onSubmit,
    isProjectProcessLoading,
    stoppedReasons,
    complete,
  } = useStopProcess({ projectProcessId });

  return (
    <Modal.Layout>
      {isProjectProcessLoading ? (
        <Modal.Preloader />
      ) : (
        <>
          <Modal.Header>Приостановка выполнения раздела</Modal.Header>
          <Modal.Form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.grid}>
              <p>Причина остановки</p>
              <p>Дата создания</p>
              <p>Дата закрытия</p>
              <div />
              {stoppedReasons!.map((task) => (
                <StopReason task={task} complete={complete} key={task.id} />
              ))}
            </div>
            <TextField
              multiline
              minRows={2}
              placeholder="Введите причину"
              label="Причина"
              {...register("content")}
              error={!!errors.content}
              helperText={errors.content?.message}
            />
            <Modal.Controls>
              <Button
                variant="contained"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Отправить
              </Button>
            </Modal.Controls>
          </Modal.Form>
        </>
      )}
    </Modal.Layout>
  );
};
