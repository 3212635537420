import { memo } from "react";
import parse from "html-react-parser";
import { Skeleton } from "@mui/material";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";
import { Card } from "src/FSD/shared/uiKit/v2";
import { useProjectProcessApplicationQuery } from "src/FSD/entities/projectProcess/api";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import styles from "./Warnings.module.scss";

type Props = {
  projectProcessId: number;
  warning: ApiCommonResponses.Warning;
};

export const Warnings = memo(({ projectProcessId, warning }: Props) => {
  const { data: application, isFetching } = useProjectProcessApplicationQuery({
    id: projectProcessId,
  });

  const isApplicationLoading = !application || isFetching;

  const titleActual = warning.actual.title;
  const titleInput = warning.input.title;
  const titleOutput = warning.output.title;
  const titleRequired = warning.required.title;

  const hasWarnings = titleActual || titleInput || titleOutput || titleRequired;

  if (isApplicationLoading) {
    return (
      <Card header={<Card.Header>Внимание !</Card.Header>}>
        <Skeleton height={35} />
      </Card>
    );
  }

  const hasApplication = Boolean(application?.messages);

  return (
    <>
      {hasWarnings && (
        <Card
          header={<Card.Header>Внимание !</Card.Header>}
          className={styles.warningsWrapper}
        >
          {warning && warning.input.data.length > 0 && (
            <>
              <h3>Требуемые задания</h3>
              <p className={styles.warning_lineHeight}>{warning.input.title}</p>
              <div className={styles.warning_flex}>
                {warning.input.data.map((warning) => (
                  <TextLink
                    key={warning.processId}
                    url={`/project-process/${warning.processId}`}
                    variant={styles.warningLink}
                  >
                    {warning.name}
                  </TextLink>
                ))}
              </div>
            </>
          )}
          {titleActual && (
            <div className={styles.actual}>
              <h3>{titleActual}</h3>
              <div className={styles.actual_flex}>
                {warning.actual.data.map((warning) => (
                  <p key={warning}>{warning}</p>
                ))}
              </div>
            </div>
          )}
          {titleOutput && (
            <div className={styles.actual}>
              <p>{titleOutput}</p>
            </div>
          )}
          {titleRequired && (
            <>
              <div className={styles.warning_lineHeight}>
                {parse(titleRequired)}
              </div>
              <div className={styles.warning_flex}>
                {warning.required.data.map((warning) => (
                  <TextLink
                    url={`/project-process/${warning.processId}`}
                    key={warning.processId}
                    variant={styles.warningLink}
                  >
                    {warning.name}
                  </TextLink>
                ))}
              </div>
            </>
          )}
        </Card>
      )}
      {hasApplication && (
        <Card header={<Card.Header>Внимание !</Card.Header>}>
          <p>
            Выбор исполнителя невозможен, так как не заполнены данные в личном
            кабинете
          </p>
          <div className={styles.applications}>
            {application!.messages.map((message) => (
              <div key={message.title} className={styles.applications_block}>
                <div className={styles.applications_block_text}>
                  <h3>{message.title}</h3>
                  {message.attributes.map((attribute: any) => (
                    <p key={attribute}>{attribute}</p>
                  ))}
                </div>
                {message.link && (
                  <Button
                    href={message.link}
                    redirect
                    target={"_blank"}
                    variant="contained"
                  >
                    Заполнить
                  </Button>
                )}
              </div>
            ))}
          </div>
        </Card>
      )}
    </>
  );
});
